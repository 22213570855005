export const palette = {
  white: '#FFFFFF',
  black: '#000000',

  gray25: '#2c3235',
  gray15: '#22252b', //'#202226',
  gray10: '#181b1f', // old '#141619',
  gray05: '#111217', // old '#0b0c0e',

  // new from figma,
  darkLayer0: '#18181A',
  darkLayer1: '#212124',
  darkLayer2: '#2a2a2f', // figma used #34343B but a bit too bright

  darkBorder1: '#34343B',
  darkBorder2: '#64646B',

  // Dashboard bg / layer 0 (light theme)
  gray90: '#F4F5F5',
  // Card bg / layer 1
  gray100: '#F4F5F5',
  // divider line
  gray80: '#D0D1D3',
  // from figma
  lightBorder1: '#E4E7E7',

  blueDarkMain: '#3CB043', // '#4165F5',
  blueDarkText: '#6E9FFF', // '#58a6ff', //'#33a2e5', // '#5790FF',
  redDarkMain: '#E5584D',
  redDarkText: '#FF5286',
  greenDarkMain: '#1A7F4B',
  greenDarkText: '#6CCF8E',
  orangeDarkMain: '#FF9900',
  orangeDarkText: '#fbad37',

  blueLightMain: '#3871DC',
  blueLightText: '#1F62E0',
  redLightMain: '#E0226E',
  redLightText: '#CF0E5B',
  greenLightMain: '#1B855E',
  greenLightText: '#0A764E',
  orangeLightMain: '#FF9900',
  orangeLightText: '#B5510D',

  'accent_accent-1': '#3CB043',
  'accent_accent-1-pressed': '#34983A',
  'accent_accent-1-hovered': '#41BE48',
  'accent_accent-2': '#4D4D4D',
  'accent_error': '#E5584D',
  'accent_error-hovered': '#CE3C31',

  'text-icon_const-primary': '#FFFFFF',
  'text-icon_const-quaternary': '#CCCCCC',
  'text-icon_tertiary': '#999999',
  'text-icon_secondary': '#E6E6E6',

  'surface_primary': '#262626',
  'surface_secondary': '#2E2E2E',

  'background_secondary': '#1F1F1F',
  'background_const-primary': '#262626',

  'border_secondary': '#404040',
  'border_teriary': '#595959'
};
